h1, h2, h3, h4, h5, h6 {

    font-weight: bold;
    text-transform: uppercase;
}


.mono {

    font-family: monospace;
}

.circle {

    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: purple;
}

.group {

    color: red;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.ww {

  width: 100px;
  padding-right: 1rem;
}


.section-title {

  color: var(--gray);
  border-left: 2px solid var(--dark);
  letter-spacing: 0.17em;
  font-weight: 500;
  margin-top: 2em;
  margin-bottom: 1em;
  padding-left: 24px;
  -webkit-font-feature-settings: "c2sc";
          font-feature-settings: "c2sc";
  font-variant: small-caps;

}


label {
  min-width: 200px
}

.form-number {

  width: 200px;
}


.links line {
  stroke: #aaa;
}

.nodes circle {
  pointer-events: all;
  stroke: none;
  stroke-width: 40px;
}

.node circle {
    fill: #fff;
    stroke: #99ccff;
    stroke-width: 3px;
}

.tooltip {

    width: 100px;
    height: 100px;
}

