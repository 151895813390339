.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ww {

  width: 100px;
  padding-right: 1rem;
}


.section-title {

  color: var(--gray);
  border-left: 2px solid var(--dark);
  letter-spacing: 0.17em;
  font-weight: 500;
  margin-top: 2em;
  margin-bottom: 1em;
  padding-left: 24px;
  font-variant: small-caps;

}


label {
  min-width: 200px
}

.form-number {

  width: 200px;
}


.links line {
  stroke: #aaa;
}

.nodes circle {
  pointer-events: all;
  stroke: none;
  stroke-width: 40px;
}
