@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-table/react-table.css';

h1, h2, h3, h4, h5, h6 {

    font-weight: bold;
    text-transform: uppercase;
}


.mono {

    font-family: monospace;
}

.circle {

    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: purple;
}

.group {

    color: red;
}
